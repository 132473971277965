<template>
  <div>
    <validation-observer
      ref="observer"
      v-slot="{ handleSubmit }"
    >
      <b-form
        @submit.stop.prevent="handleSubmit(onSubmit)"
      >
        <b-modal
          id="upsert-checkScript-modal"
          v-model="isShow"
          size="xl"
          :title="modalTitle"
          no-close-on-backdrop
          scrollable
          :ok-disabled="!hasPermissionToSave"
          @show="resetForm"
          @ok="save"
        >
          <b-overlay :show="isLoading || isSaving">

            <b-alert
              v-if="isManaged === true"
              variant="info"
              show
            >
              <div class="alert-body">
                <span><strong>Managed</strong> check scripts are accessable by all tenants. It´s not possible to overwrite the parameters, the script itsef or the name by the tenant.</span>
              </div>
            </b-alert>

            <BRow>
              <BCol>
                <validation-provider
                  v-slot="validationContext"
                  name="Name"
                  :rules="{ required: true, max: 100 }"
                >
                  <b-form-group
                    label="Name:"
                    label-for="input-name"
                  >
                    <b-form-input
                      id="input-name"
                      v-model="form.displayName"
                      name="input-name"
                      placeholder="Enter name"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-name-live-feedback"
                      :disabled="!hasPermissionToSave"
                    />
                    <b-form-invalid-feedback id="input-name-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <b-form-group
                  label="System type:"
                  label-for="input-systemtype"
                  description="This script is available for systems of the selected type."
                >
                  <b-form-select
                    v-model="form.systemType"
                    :options="systemTypeOptions"
                    :disabled="!hasPermissionToSave"
                  />
                </b-form-group>

                <b-form-group
                  label="UI Elements"
                  label-for="input-systemtype"
                  description="Selected UI elements will be highlighted with the status code color."
                >
                  <v-select
                    v-model="form.uiAttributes"
                    :options="uiAttributeOptions"
                    multiple
                    :disabled="!hasPermissionToSave"
                  />
                </b-form-group>

                <div class="clearfix mt-5">
                  <h4 class="float-left">
                    Parameters
                  </h4>
                  <span
                    v-if="hasPermissionToSave"
                    class="muted float-right"
                  >
                    <b-link @click="addParameter">
                      Add parameter
                    </b-link>
                  </span>
                </div>
                <p class="muted">
                  Parameters available in the script. They can be set in the monitors.
                </p>

                <b-form-group>
                  <table class="table table-sm">
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th>Name</th>
                        <th>Default value</th>
                        <th>RegEx validation</th>
                        <th>Description</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(parameter, index) in form.parameters"
                        :key="index"
                      >
                        <td>
                          <b-form-select
                            v-model="parameter.type"
                            size="sm"
                            :disabled="!hasPermissionToSave"
                          >
                            <b-form-select-option :value="0">
                              Text
                            </b-form-select-option>
                            <b-form-select-option :value="1">
                              Number
                            </b-form-select-option>
                            <b-form-select-option :value="2">
                              True/False
                            </b-form-select-option>
                          </b-form-select>
                        </td>
                        <td>
                          <b-form-input
                            v-model="parameter.name"
                            size="sm"
                            :disabled="!hasPermissionToSave"
                            maxlength="20"
                          />
                        </td>
                        <td>
                          <b-form-input
                            v-if="parameter.type === 0 || parameter.type === 1"
                            v-model="parameter.defaultValue"
                            :type="parameter.type === 0 ? 'text' : 'number'"
                            size="sm"
                            :disabled="!hasPermissionToSave"
                          />
                          <b-form-select
                            v-if="parameter.type === 2"
                            v-model="parameter.defaultValue"
                            size="sm"
                            :disabled="!hasPermissionToSave"
                          >
                            <b-form-select-option :value="'0'">
                              False
                            </b-form-select-option>
                            <b-form-select-option :value="'1'">
                              True
                            </b-form-select-option>
                          </b-form-select>

                          <small
                            v-if="parameter.validationMessage"
                            class="text-danger"
                          >
                            {{ parameter.validationMessage }}
                          </small>
                        </td>
                        <td>
                          <b-form-input
                            v-if="parameter.type === 0"
                            v-model="parameter.validationRegexPattern"
                            size="sm"
                            maxlength="50"
                            :disabled="!hasPermissionToSave"
                          />
                        </td>
                        <td>
                          <b-form-textarea
                            v-model="parameter.description"
                            rows="1"
                            max-rows="3"
                            maxlength="500"
                            :disabled="!hasPermissionToSave"
                          />
                        </td>
                        <td>
                          <b-button
                            size="sm"
                            variant="outline-danger"
                            @click="removeParameter(parameter)"
                          >
                            <feather-icon
                              v-if="hasPermissionToSave"
                              icon="TrashIcon"
                            />
                          </b-button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-form-group>

              </BCol>
              <BCol>
                <b-form-group
                  label="Code:"
                  label-for="input-code"
                  class="code-form-group"
                >
                  <span
                    id="codeHelpPopover"
                    class="muted float-right"
                  >
                    <feather-icon icon="HelpCircleIcon" />
                  </span>

                  <b-popover
                    target="codeHelpPopover"
                    triggers="click"
                  >
                    <p>
                      <strong>Script sceleton (execute function is required):</strong><br>
                      <code>function execute(args) {<br>   return new Result(MonitorStatus.Ok);<br>}</code>
                    </p>

                    <p>
                      <strong>MonitorStatus enum:</strong><br>
                      <code>Ok<br>Warning<br>Error</code>
                    </p>
                    <p>
                      <strong>Example</strong><br>
                      <code>function execute(args) {<br>   if(payload.temperature > args['temperature']) { return new Result(MonitorStatus.Warning); }<br>}</code>
                    </p>
                  </b-popover>

                  <b-form-textarea
                    v-model="code"
                    rows="16"
                    :disabled="!hasPermissionToSave"
                  />
                </b-form-group>
              </BCol>
            </BRow>
          </b-overlay>

          <b-alert
            :show="!hasPermissionToSave"
            variant="info"
          >
            You do not have permission to save changes. This is a readonly view.
          </b-alert>
        </b-modal>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>

import {
  BRow, BCol, BAlert, BModal, BForm, BFormInput, BFormGroup, BFormInvalidFeedback, BOverlay, BFormSelect, BFormSelectOption, BFormTextarea, BLink, BPopover, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

// eslint-disable-next-line no-unused-vars
import {
  // eslint-disable-next-line no-unused-vars
  required, email, max, mapServerFieldValidationErrors,
} from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import ManagedCheckScriptService from '@/service/managedCheckScript.service'
import CheckScriptService from '@/service/checkScript.service'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormInvalidFeedback,
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BOverlay,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    BLink,
    BPopover,
    BButton,
  },
  data() {
    return {
      isShow: false,
      isLoading: false,
      checkScriptId: null,
      isSaving: false,
      isManaged: false,
      form: {
        displayName: '',
        systemType: null,
        parameters: [],
        uiAttributes: [],
      },
      code: '',
      systemTypeOptions: [
        { value: null, text: 'All systems' },
        { value: 1000, text: 'DataDomain' },
        { value: 1008, text: 'NetWorker' },
        { value: 1009, text: 'ONEInsight Agent' },
        { value: 1010, text: 'Avamar' },
        { value: 1011, text: 'Veeam' },
        { value: 1012, text: 'PowerProtect' },
        { value: 21000, text: 'ONE Backup' },
        { value: 21001, text: 'Generic' },
      ],
      uiAttributeOptionsBySystemType: [
        // All
        { systemType: null, uiAttribute: 'updateTimestampUtc' },
        { systemType: null, uiAttribute: 'internalNote' },
        { systemType: null, uiAttribute: 'account.name' },
        { systemType: null, uiAttribute: 'organizationUnit.displayName' },
        { systemType: null, uiAttribute: 'hasProActiveService' },

        // DataDomain
        { systemType: 1000, uiAttribute: 'hostname' },
        { systemType: 1000, uiAttribute: 'model' },
        { systemType: 1000, uiAttribute: 'version' },
        { systemType: 1000, uiAttribute: 'serial' },
        { systemType: 1000, uiAttribute: 'currentAlertCount' },
        { systemType: 1000, uiAttribute: 'alertLast72HoursCount' },
        { systemType: 1000, uiAttribute: 'lastAlertOnUtc' },
        { systemType: 1000, uiAttribute: 'factor' },
        { systemType: 1000, uiAttribute: 'capacity' },
        { systemType: 1000, uiAttribute: 'used' },
        { systemType: 1000, uiAttribute: 'usedPercentage' },
        { systemType: 1000, uiAttribute: 'preComp' },
        { systemType: 1000, uiAttribute: 'temperature' },
        { systemType: 1000, uiAttribute: 'usageExceedsLimitBase365On' },
        { systemType: 1000, uiAttribute: 'usageExceedsLimitBase90On' },

        // NetWorker
        { systemType: 1008, uiAttribute: 'currentAlertCount' },

        // Avamar
        { systemType: 1010, uiAttribute: 'serverUtilization' },
      ],
    }
  },
  computed: {
    uiAttributeOptions() {
      return this.uiAttributeOptionsBySystemType
        .filter(x => x.systemType === this.form.systemType || x.systemType === null)
        .map(x => x.uiAttribute)
    },
    isCreateState() {
      return this.checkScriptId === null || typeof this.checkScriptId === 'undefined'
    },
    checkScriptService() {
      return this.isManaged ? ManagedCheckScriptService : CheckScriptService
    },
    modalTitle() {
      if (this.isManaged === true) {
        return this.isCreateState ? 'Create managed CheckScript' : 'Update managed CheckScript'
      }

      return this.isCreateState ? 'Create CheckScript' : 'Update CheckScript'
    },
    hasPermissionToSave() {
      if (this.isManaged === true && this.$can('Watchdog.Monitors.ManageManaged') === false) {
        return false
      }

      if (this.isCreateState === true) {
        return this.$can('Watchdog.Monitors.Create')
      }

      return this.$can('Watchdog.Monitors.Update')
    },
  },
  methods: {
    resetForm() {
      this.form = {
        displayName: '',
        systemType: null,
        parameters: [],
        uiAttributes: [],
      }
    },
    show(checkScriptId, isManaged) {
      this.isShow = true
      this.resetForm()

      this.isManaged = isManaged
      this.checkScriptId = checkScriptId

      if (this.isCreateState === false) {
        this.isLoading = true
        this.checkScriptService.getAsync(checkScriptId)
          .then(result => {
            this.form.displayName = result.data.displayName
            this.form.systemType = result.data.systemType
            this.form.parameters = result.data.parameters
            this.form.uiAttributes = result.data.uiAttributes || []

            this.checkScriptService.getScriptAsync(checkScriptId)
              .then(scriptResult => {
                this.code = scriptResult.data
              })
          })
          .finally(() => {
            this.isLoading = false
          })
      } else {
        this.code = 'function execute(args) {\n\treturn new Result(MonitorStatus.Ok);\n}'
      }
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    addParameter() {
      this.form.parameters.push({
        name: `param_${this.form.parameters.length + 1}`,
      })
    },
    removeParameter(parameter) {
      this.form.parameters = this.form.parameters.filter(x => x.name !== parameter.name)
    },
    async save(event) {
      event.preventDefault()

      let hasValidationError = false
      for (let i = 0; i < this.form.parameters.length; i += 1) {
        const parameter = this.form.parameters[i]
        delete parameter.validationMessage // reset validation message

        if (!parameter.defaultValue) {
          parameter.validationMessage = 'Default value is required'
          hasValidationError = true
        } else if (parameter.validationRegexPattern) {
          const regex = new RegExp(parameter.validationRegexPattern)
          if (regex.test(parameter.defaultValue) === false) {
            this.$set(this.form.parameters[i], 'validationMessage', 'Value is not valid')
            hasValidationError = true
          }
        }
      }

      if (hasValidationError) {
        return // stop processing if validation errors occured
      }

      const self = this
      this.isSaving = true

      try {
        const data = this.form
        data.scriptContentBase64 = btoa(this.code)

        if (this.isCreateState) {
          const response = await this.checkScriptService.createAsync(data)

          self.$emit('checkScriptCreated', {
            checkScript: response.data,
          })

          self.$toast.success('CheckScript has been created.', {
            icon: true,
          })
        } else {
          const response = await this.checkScriptService.updateAsync(this.checkScriptId, data)

          self.$emit('checkScriptUpdated', {
            checkScript: response.data,
          })
          self.$toast.success('CheckScript has been updated.', {
            icon: true,
          })
        }

        self.isShow = false
      } catch (e) {
        const errors = mapServerFieldValidationErrors(e.response, {
          displayName: 'DisplayName',
          systemType: 'SystemType',
        })

        self.$refs.observer.setErrors(errors)

        self.$swal({
          title: 'Something went wrong!',
          text: e.response.data.error.message,
          icon: 'error',
        }).then(() => {
          self.isShow = true
        })
      } finally {
        self.isSaving = false
      }
    },
  },
}
</script>

<style scoped>
    .code-form-group >>> label {
        float: left;
    }
</style>
