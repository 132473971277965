<template>
  <div class="monitor-list">
    <b-list-group>
      <MonitorListItem
        v-for="checkScript in orderedCheckScripts"
        :key="checkScript.id"
        :check-script="checkScript"
        :monitor="checkScript.monitor"
        :show-details="showDetails"
        @changeEnabled="onChangeEnabled"
        @clickEditMonitor="onClickEditMonitor"
        @clickEditScript="onClickEditScript"
        @clickDelete="onClickDelete"
      />
    </b-list-group>

    <EditMonitorModal
      ref="editMonitorModal"
      @cancelModal="$emit('editMonitorModalClose', $event)"
      @monitorCreated="$emit('reloadData')"
      @monitorUpdated="$emit('reloadData')"
    />
  </div>
</template>

<script>

import {
  BListGroup,
} from 'bootstrap-vue'

import MonitorListItem from './monitorListItem.vue'
import EditMonitorModal from './editMonitorModal.vue'

import MonitorService from '@/service/monitor.service'
import CheckScriptService from '@/service/checkScript.service'
import ManagedCheckScriptService from '@/service/managedCheckScript.service'

export default {
  components: {
    BListGroup, MonitorListItem, EditMonitorModal,
  },
  props: {
    systemType: {
      type: Number,
      required: false,
      default: null,
    },
    checkScripts: {
      type: Array,
      default: () => [],
    },
    showDetails: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    orderedCheckScripts() {
      return this.checkScripts
        .concat()
        .sort((a, b) => ((a.monitor || {}).sort > (b.monitor || {}).sort ? 1 : -1))
    },
  },
  methods: {
    onChangeEnabled(event) {
      if (event.enabled === true) {
        this.$refs.editMonitorModal.show(event.checkScript.id, (event.monitor || {}).id)
      } else if (event.monitor !== null) {
        // disable monitor
        MonitorService.disableAsync(event.monitor.id)
          .then(() => {
            this.$toast.success('Monitor disabled', {
              icon: true,
            })
          })
          .catch(e => {
            this.$swal({
              title: 'Something went wrong!',
              text: e.response.data.error.message,
              icon: 'error',
            })
          })
      }
    },
    onClickEditMonitor(event) {
      this.$refs.editMonitorModal.show(event.checkScript.id, (event.monitor || {}).id)
    },
    onClickEditScript(event) {
      this.$emit('clickEditScript', event)
    },
    onClickDelete(event) {
      const checkScriptService = event.checkScript.isManaged === true ? ManagedCheckScriptService : CheckScriptService

      MonitorService.getAllListAsync({ scriptIds: [event.checkScript.id] })
        .then(result => {
          const overwriteMonitors = result.filter(x => x.systemId != null)
          if (overwriteMonitors.length > 0) {
            this.$swal({
              title: 'Unable to delete',
              text: `This monitor is overwritten in ${overwriteMonitors.length} systems. Please reset the monitor on all systems to it´s global state before deleting it.`,
              icon: 'error',
            })
          } else {
            const self = this
            this.$swal({
              title: 'Are you sure?',
              text: 'Do you really want to delete the monitor?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Yes, delete it!',
            }).then(swalResult => {
              if (swalResult.isConfirmed) {
                checkScriptService.deleteAsync(event.checkScript.id)
                  .then(() => {
                    self.$emit('reloadData')
                    self.$toast.success('Deleted', {
                      icon: true,
                    })
                  })
              }
            })
          }
        })
    },
  },
}

</script>
