<template>
  <div>
    <b-list-group-item
      class="flex-column align-items-start"
    >
      <div class="d-flex w-100 justify-content-between">
        <div>
          <h5 class="mb-0 text-primary">
            <b-form-checkbox
              v-model="(monitor || {}).enabled"
              switch
              :disabled="!isEditEnabled"
              @change="onClickCheckScript"
            >
              <small>({{ (monitor || {}).sort || 0 }})</small>
              {{ checkScript.displayName }}
            </b-form-checkbox>
          </h5>
          <p
            v-if="safeMonitor.notes"
            class="text-muted ml-5"
          >{{ safeMonitor.notes }}
          </p>
        </div>

        <div style="width: 300px; text-align: right">
          <b-badge
            v-if="(monitor || {}).isTemplate === true"
            class="mr-1"
            pill
            variant="success"
          >
            Template
          </b-badge>
          <b-badge
            v-if="checkScript.isManaged === true"
            class="mr-1"
            pill
            variant="info"
          >
            Managed
          </b-badge>
          <b-dropdown
            v-if="isEditEnabled"
            size="sm"
            right
          >
            <template #button-content>
              <feather-icon icon="SettingsIcon" />
            </template>
            <b-dropdown-item
              @click="onClickEditMonitor"
            >
              Manage monitor
            </b-dropdown-item>
            <b-dropdown-item
              v-if="checkScript.isManaged === false || isHost === true"
              @click="onClickEditScript"
            >
              Manage script
            </b-dropdown-item>

            <template
              v-if="checkScript.isManaged === false || isHost === true"
            >
              <b-dropdown-divider />

              <b-dropdown-item
                variant="danger"
                @click="onClickDelete"
              >
                Delete
              </b-dropdown-item>
            </template>
          </b-dropdown>
        </div>
      </div>

      <div v-show="showDetails">

        <div class="ml-5 mt-1">
          <b-form-checkbox
            v-model="(monitor || {}).enableInfoAlert"
            disabled
            class="custom-control-inline"
          >
            Info alert
          </b-form-checkbox>

          <b-form-checkbox
            :checked="safeMonitor.enableWarningAlert"
            disabled
            class="custom-control-inline"
          >
            Warning alert
          </b-form-checkbox>

          <b-form-checkbox
            :checked="safeMonitor.enableErrorAlert"
            disabled
            class="custom-control-inline"
          >
            Error alert
          </b-form-checkbox>

          <div v-if="checkScript.parameters.length > 0">
            <hr>
            <table class="table table-sm">
              <thead>
                <tr>
                  <th>Parameter</th>
                  <th>Value</th>
                  <th>Default value</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="parameter in checkScript.parameters"
                  :key="parameter.name"
                >
                  <td>
                    {{ parameter.name }}
                    <feather-icon
                      v-if="parameter.description"
                      v-b-popover.hover.bottom="parameter.description"

                      icon="HelpCircleIcon"
                      title="Description"
                    />

                    <span
                      v-if="parameter.isRequired"
                      class="muted"
                    >
                      <br> <small class="text-muted">(required)</small>
                    </span>
                  </td>
                  <td>
                    {{ getMonitorArgumentByParameterName(parameter.name) || '-' }}
                  </td>
                  <td>{{ parameter.defaultValue }}</td>
                </tr>
              </tbody>
            </table>
            <hr>
          </div>
        </div>
      </div>
    </b-list-group-item>
  </div>
</template>

<script>
import {
  BListGroupItem, BFormCheckbox, BBadge, VBPopover, BDropdown, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'

import { mapGetters } from 'vuex'

export default {
  components: {
    BListGroupItem, BFormCheckbox, BBadge, BDropdown, BDropdownItem, BDropdownDivider,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    checkScript: {
      type: Object,
      required: true,
      default: () => {},
    },
    monitor: {
      type: Object,
      required: false,
      default: () => {},
    },
    showDetails: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      isHost: 'auth/isHost',
    }),
    safeMonitor() {
      return this.monitor || {}
    },
    isTemplateDerivedMonitor() {
      return this.safeMonitor.templateId !== null
    },
    isEditEnabled() {
      if (this.checkScript.isManaged === false) {
        return true
      }

      if (this.isTemplateDerivedMonitor === true && this.isHost) {
        return true
      }

      if (this.isTemplateDerivedMonitor === false) {
        return true
      }

      return false
    },
  },
  methods: {
    onClickCheckScript(val) {
      this.$emit('changeEnabled', {
        enabled: val,
        checkScript: this.checkScript,
        monitor: this.monitor,
      })
    },
    onClickEditMonitor() {
      this.$emit('clickEditMonitor', {
        checkScript: this.checkScript,
        monitor: this.monitor,
      })
    },
    onClickEditScript() {
      this.$emit('clickEditScript', {
        checkScript: this.checkScript,
        monitor: this.monitor,
      })
    },
    onClickDelete() {
      this.$emit('clickDelete', {
        checkScript: this.checkScript,
        monitor: this.monitor,
      })
    },
    getMonitorArgumentByParameterName(parameterName) {
      if (this.monitor) {
        return this.monitor.scriptArguments[parameterName]
      }

      return null
    },
  },
}

</script>
