<template>
  <div>
    <portal to="breadcrumb-right">
      <b-dropdown
        v-if="$can('Watchdog.Monitors.Create')"
        size="sm"
        text="Manage"
        right
      >
        <template #button-content>
          <feather-icon
            icon="SettingsIcon"
          />
        </template>
        <b-dropdown-item
          v-if="$can('Watchdog.Monitors.Create')"
          @click="$refs.upsertModal.show()"
        >
          <feather-icon
            icon="LinkIcon"
          />
          <span class="ml-1 text-middle">
            Create CheckScript...
          </span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="$can('Watchdog.Monitors.Create') && $can('Watchdog.Monitors.ManageManaged')"
          @click="$refs.upsertModal.show(null, true)"
        >
          <feather-icon
            icon="LinkIcon"
          />
          <span class="ml-1 text-middle">
            Create managed CheckScript...
          </span>
        </b-dropdown-item>
      </b-dropdown>
    </portal>

    <b-row>
      <b-col cols="12">

        <b-card>
          <b-form inline>
            <b-form-group>
              <b-form-checkbox
                v-model="showDetails"
              >
                Show details
              </b-form-checkbox>
            </b-form-group></b-form>

        </b-card>

        <div class="nav-wrapper">
          <b-card no-body>
            <b-tabs
              pills
              card
              vertical
            >
              <b-tab
                v-for="systemType in availableSystemTypes"
                :key="systemType"
                :title="getMonitorSystemTypeDisplayName(systemType)"
                :active="selectedSystemType === systemType"
                @click="selectedSystemType = systemType"
              >
                <b-card-text v-if="selectedSystemType === systemType">
                  <b-row>
                    <b-col>
                      <MonitorList
                        :system-type="systemType"
                        :check-scripts="filteredCheckScripts"
                        :show-details="showDetails"
                        @editMonitorModalClose="onEditMonitorModalClose"
                        @clickEditScript="onClickEditScript"
                        @reloadData="onReloadData"
                      />

                    </b-col>
                  </b-row>
                </b-card-text>
              </b-tab>
            </b-tabs>
          </b-card>
        </div>
      </b-col>
    </b-row>

    <ManagedCheckScriptUpsertModal
      ref="upsertModal"
      @checkScriptCreated="loadData"
      @checkScriptUpdated="loadData"
    />

  </div>
</template>
<script>
import {
  BCol, BRow, BCard, BCardText, BTabs, BTab, BDropdown, BDropdownItem, BForm, BFormGroup, BFormCheckbox,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

import MonitorList from './monitorList.vue'
import ManagedCheckScriptUpsertModal from './managedCheckScriptUpsertModal.vue'

import MonitorService from '@/service/monitor.service'
import CheckScriptService from '@/service/checkScript.service'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BTabs,
    BTab,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormGroup,
    BFormCheckbox,
    MonitorList,
    ManagedCheckScriptUpsertModal,
  },
  data() {
    return {
      selectedSystemType: null,
      checkScripts: [],
      monitors: [],
      showDetails: false,
    }
  },
  computed: {
    ...mapGetters({
      isHost: 'auth/isHost',
    }),
    filteredCheckScripts() {
      return this.checkScripts.filter(x => x.systemType === this.selectedSystemType)
    },
    availableSystemTypes() {
      const systemTypes = [...new Set(this.checkScripts.map(x => x.systemType))]

      // sort
      return systemTypes.sort((a, b) => a - b)
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    getValidMonitorForCheckScript(checkScript) {
      // get valid monitors of correct system type
      const monitors = this.monitors.filter(x => x.systemType === checkScript.systemType && x.checkScriptId === checkScript.id)

      // there should exist only 1 monitor!
      if (monitors.length === 1) {
        return monitors[0]
      }
      if (monitors.length > 1) {
        this.$log.error(`Multiple valid monitors found for checkScript ${checkScript.id}. Use ${monitors[0].id}`)
        return monitors[0]
      }

      return null
    },
    async loadData() {
      const self = this
      this.isLoading = true

      this.checkScripts = await CheckScriptService.getAllListAsync()
      const allMonitors = await MonitorService.getAllListAsync()
      this.monitors = allMonitors.filter(x => x.systemId === null) // filter no system specific monitors

      this.checkScripts.forEach(checkScript => {
        const validMonitor = self.getValidMonitorForCheckScript(checkScript)
        self.$set(checkScript, 'monitor', validMonitor)
      })

      this.isLoading = false
    },
    getMonitorSystemTypeDisplayName(systemType) {
      if (systemType === null) {
        return 'Global'
      }

      return this.$options.filters.systemTypeDisplayName(systemType)
    },
    onEditMonitorModalClose() {
      this.loadData()
    },
    onClickEditScript(event) {
      this.$refs.upsertModal.show(event.checkScript.id, event.checkScript.isManaged)
    },
    onReloadData() {
      this.loadData()
    },
  },
}
</script>

<style scoped>
  .nav-wrapper >>> ul.nav {
    justify-content: flex-start!important;
  }
</style>
